import { all, takeLatest, call, put, select } from "redux-saga/effects";
import { actionTypes } from "./reducer";
// import { validateRole, role } from '../../utils/cep-role-validation';
import { message } from "antd";
import {
  getCepProvinces as cepServiceableProvinceService,
  getCepCities as cepServiceableCitiesService,
  getCepBarangays as cepServiceableBarangayService,
  createCepDraftBooking as createCepDraftBookingService,
  updateCepDraftBooking as updateCepDraftBookingService,
  getLocalTime as getLocalTimeService,
  getPortCodes as getPortCodeService,
  getCepPackages as getCepPackagesService,
  createCepPackage as createCepPackageService,
  updateCepPackage as updateCepPackageService,
  cepBulkUpload as bulkUploadService,
  getCepBookingDetails as getCepBookingDetailsService,
  deleteCepPackages as deleteCepPackageService,
  cancelCepPackages as cancelCepPackageService,
  verifyDownloadTransmittal as verifyDownloadTransmittalService,
  verifyDownloadWaybill as verifyDownloadWaybillService,
  verifyDownloadPackageWaybill as verifyDownloadPackageWaybillService,
} from "./service";
import processAction from "../../utils/processAction";


function* cepBulkUpload({ body, bookingNumber }) {
  try {
    const state = yield select();
    const currentPackage = state.cepBooking.cepPackages;
    const data = yield call(bulkUploadService, body, bookingNumber);
    const failedPackages = data.packages
      .filter((e) =>
        e.valid === false
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      )
      .map((x) => ({
        errors: x.errors,
        row: x.row,
        description: x.packageInfo.details.description,
      })) || [];
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const successfulPackages = data.packages
      .filter((e) =>
        e.valid !== false
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      )
      .map((x, i) => {
        x.packageInfo.row = currentPackage.length + i + 1;
        return x.packageInfo;
      }) || [];
    message.success('Upload Success');
    yield put({
      type: actionTypes.BULK_CEP_UPLOAD_SUCCESS, data: {
        successfulPackages,
        bulkErrors: {
          packages: failedPackages,
          totalPackages: data.summary.total,
          failedPackages: data.summary.failed,
        },
      },
    });
  } catch (error) {
    yield put({ type: actionTypes.BULK_CEP_UPLOAD_FAILED, error });
  }
}

function* getCepPackages({ id }) {
  yield processAction({
    params: id,
    service: getCepPackagesService,
    success: actionTypes.GET_CEP_PACKAGES_SUCCESS,
    failed: actionTypes.GET_CEP_PACKAGES_FAILED
  });
}

function* verifyDownloadTransmittal({ bookingNumber }) {
  try {
    yield call(verifyDownloadTransmittalService,bookingNumber);
    yield put({
      type: actionTypes.VERIFY_DOWNLOAD_TRANSMITTAL_SUCCESS,
    });
  }
  catch (error) {
    message.error(error.message);
    yield put({ type: actionTypes.VERIFY_DOWNLOAD_TRANSMITTAL_FAILED, error });
  }
}

function* verifyDownloadWaybill({ bookingNumber }) {
  try {
    yield call(verifyDownloadWaybillService,bookingNumber);
    yield put({
      type: actionTypes.VERIFY_DOWNLOAD_WAYBILL_SUCCESS,
    });
  }
  catch (error) {
    yield put({ type: actionTypes.VERIFY_DOWNLOAD_WAYBILL_FAILED, error });
  }
}

function* verifyDownloadPackageWaybill({ trackingNumber }){
  try {
    yield call(verifyDownloadPackageWaybillService,trackingNumber);
    yield put({
      type: actionTypes.VERIFY_DOWNLOAD_PACKAGE_WAYBILL_SUCCESS,
    });
  }
  catch (error) {
    yield put({ type: actionTypes.VERIFY_DOWNLOAD_PACKAGE_WAYBILL_FAILED, error });
  }
}

function* getCepProvinces() {
  yield processAction({
    service: cepServiceableProvinceService,
    success: actionTypes.GET_CEP_SERVICEABLE_PROVINCES_SUCCESS,
    failed: actionTypes.GET_CEP_SERVICEABLE_PROVINCES_FAILED,
  });
}

function* getCepCities({ id }) {
  try {
    const payload = yield call(cepServiceableCitiesService, id);
    yield put({ type: actionTypes.GET_CEP_SERVICEABLE_CITIES_SUCCESS, payload });
  } catch (error) {
    if (error.status === "NOT_FOUND") {
      const state = yield select();
      const provinces = state.cepBooking.provinces;
      if (provinces && provinces.length > 0) {
        const province = provinces.find((x) => x.id === id);
        const { name = "" } = province;
        message.error(`${name} Province is not covered at the moment.`);
      }
    } else {
    }
    message.error(error.message);
    yield put({ type: actionTypes.GET_CEP_SERVICEABLE_CITIES_FAILED, error });
  }
}

function* getPortCodes({ body }) {
  try {
    const payload = yield call(getPortCodeService, body);
    yield put({ type: actionTypes.GET_PORT_CODES_SUCCESS, payload });
  } catch (error) {
    yield put({ type: actionTypes.GET_PORT_CODES_FAILED, error });
  }
}

function* getCepBarangays({ id }) {
  yield processAction({
    params: id,
    service: cepServiceableBarangayService,
    success: actionTypes.GET_CEP_SERVICEABLE_BARANGAYS_SUCCESS,
    failed: actionTypes.GET_CEP_SERVICEABLE_BARANGAYS_FAILED,
  });
}

function* getPickupLocation() {
  try {
    // const provinces = yield call(cepServiceableProvinceService);
    // const selectedProvince = provinces.find((x) => x.name === body.province);
    // const cities = yield call(cepServiceableCitiesService, selectedProvince.id);
    // const selectedCities = cities.cities.find((x) => x.name === body.city
    // );
    // const data = {
    //   provinces: provinces,
    //   cities: cities.cities,
    //   barangays: barangays.barangays,
    // };
    const state = yield select();
    yield put({ type: actionTypes.GET_LOCATION_SUCCESS, state });
  } catch (error) {
    yield put({ type: actionTypes.GET_LOCATION_FAILED, error });
  }
};

function* createCepPackage({ body }) {
  yield processAction({
    params: body,
    service: createCepPackageService,
    success: actionTypes.CREATE_CEP_PACKAGE_SUCCESS,
    failed: actionTypes.CREATE_CEP_PACKAGE_FAILED
  });
}

function* updateCepPackage({ body }) {
  yield processAction({
    params: body,
    service: updateCepPackageService,
    success: actionTypes.UPDATE_CEP_PACKAGE_SUCCESS,
    failed: actionTypes.UPDATE_CEP_PACKAGE_FAILED
  });
}

function* getLocalTime() {
  try {
    const payload = yield call(getLocalTimeService);
    if (payload.formatted && payload.formatted) {
      yield put({ type: actionTypes.GET_LOCAL_TIME_SUCCESS, payload });
    } else {
      yield put({ type: actionTypes.GET_LOCAL_TIME_ERROR });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_LOCAL_TIME_ERROR });
  }
}

function* createCepDraftBooking({ body }) {
  yield processAction({
    params: body,
    service: createCepDraftBookingService,
    success: actionTypes.CREATE_CEP_DRAFT_BOOKING_SUCCESS,
    failed: actionTypes.CREATE_CEP_DRAFT_BOOKING_FAILED
  });
}

function* updateCepDraftBooking({ body }) {
  yield processAction({
    params: body,
    service: updateCepDraftBookingService,
    success: actionTypes.UPDATE_CEP_DRAFT_BOOKING_SUCCESS,
    failed: actionTypes.UPDATE_CEP_DRAFT_BOOKING_FAILED
  });
}

function* finalizeCepBooking({ body }) {
  yield processAction({
    params: body,
    service: updateCepDraftBookingService,
    success: actionTypes.FINALIZE_CEP_BOOKING_SUCCESS,
    failed: actionTypes.FINALIZE_CEP_BOOKING_FAILED
  });
}

function* cancelCepBooking({ body }) {
  yield processAction({
    params: body,
    service: updateCepDraftBookingService,
    success: actionTypes.CANCEL_CEP_BOOKING_SUCCESS,
    failed: actionTypes.CANCEL_CEP_BOOKING_FAILED
  });
}

// function* deleteCepPackages({ body }) {
//   yield processAction({
//     params: body,
//     service: deleteCepPackageService,
//     success: actionTypes.DELETE_CEP_PACKAGES_SUCCESS,
//     failed: actionTypes.DELETE_CEP_PACKAGES_FAILED
//   });
//   if(body.bookingNumber){

//   }
// }

function* deleteCepPackages({body} ) {
  try {
    let payload = yield call(deleteCepPackageService, body); 
    message.success('Package Successfully Deleted');
    yield put({ type: actionTypes.DELETE_CEP_PACKAGES_SUCCESS, payload: payload.packages });
    // yield put({ type: actionTypes.GET_CEP_PACKAGES, id: body.bookingNumber });
  } catch (error) {
    yield put({ type: actionTypes.DELETE_CEP_PACKAGES_FAILED, error });
  }
}

function* cancelCepPackages({body} ) {
  try {
    let payload = yield call(cancelCepPackageService, body);
    message.success('Package is Successfully Cancelled');
    yield put({ type: actionTypes.CANCEL_CEP_PACKAGES_SUCCESS, payload: payload.packages });
    yield put({ type: actionTypes.GET_CEP_PACKAGES, id: body.bookingNumber });
  } catch (error) {
    yield put({ type: actionTypes.CANCEL_CEP_PACKAGES_FAILED, error });
  }
}

function* getCepBookingDetails({ id }) {
  try {
    let payload = yield call(getCepBookingDetailsService, id);
    const state = yield select();
    const bookingAccountId = payload.accountId;
     const { cepDashboard } = state;
    
    const { cepProfile,
      //  cepUserDetails
      cepAccountDetails
       } = cepDashboard;

    // const { profile } = state;
    // const userId  = profile.data.userId;
    // const authorities  = cepUserDetails[0].authorities;
    // const validatedRole = validateRole(authorities,
    //   [role.ROLE_BOOK_MANAGED_ACCOUNT]);
    // const currentProfile = validatedRole ?
    //   yield call(getCepProfileService, userId) : { accounts: [] };
    // payload.accounts = currentProfile.accounts;
    // const { accountId } = validatedRole ? payload.accounts[0] : cepProfile || {};

    const { accountId } = cepProfile || {};
    let cepaccountId
    if(accountId) {
      cepaccountId = accountId;
    }  else {
      cepaccountId = cepAccountDetails[0].accountId;
    }

    // redirect to home if invalid client id
    if (bookingAccountId !== cepaccountId) {
      message.error('Client Booking Mismatch');
      payload.isMismatch = true;
    }else{
      payload.isMismatch = false;
    }
    yield put({ type: actionTypes.GET_CEP_BOOKING_DETAILS_SUCCESS, payload });
  } catch (error) {
    yield put({ type: actionTypes.GET_CEP_BOOKING_DETAILS_FAILED, error });
  }
}

function* watchCepBookingRequests() {
  yield all([
    takeLatest(actionTypes.GET_CEP_SERVICEABLE_PROVINCES_REQUEST, getCepProvinces),
    takeLatest(actionTypes.GET_CEP_SERVICEABLE_CITIES_REQUEST, getCepCities),
    takeLatest(actionTypes.GET_CEP_SERVICEABLE_BARANGAYS_REQUEST, getCepBarangays),
    takeLatest(actionTypes.GET_LOCATION_REQUEST, getPickupLocation),
    takeLatest(actionTypes.GET_LOCAL_TIME_REQUEST, getLocalTime),
    takeLatest(actionTypes.GET_PORT_CODES_REQUEST, getPortCodes),
    takeLatest(actionTypes.CREATE_CEP_DRAFT_BOOKING_REQUEST, createCepDraftBooking),
    takeLatest(actionTypes.UPDATE_CEP_DRAFT_BOOKING_REQUEST, updateCepDraftBooking),
    takeLatest(actionTypes.GET_CEP_PACKAGES, getCepPackages),
    takeLatest(actionTypes.CREATE_CEP_PACKAGE, createCepPackage),
    takeLatest(actionTypes.UPDATE_CEP_PACKAGE, updateCepPackage),
    takeLatest(actionTypes.BULK_CEP_UPLOAD_REQUEST, cepBulkUpload),
    takeLatest(actionTypes.FINALIZE_CEP_BOOKING_REQUEST, finalizeCepBooking),
    takeLatest(actionTypes.CANCEL_CEP_BOOKING_REQUEST, cancelCepBooking),
    takeLatest(actionTypes.GET_CEP_BOOKING_DETAILS_REQUEST, getCepBookingDetails),
    takeLatest(actionTypes.DELETE_CEP_PACKAGES_REQUEST, deleteCepPackages),
    takeLatest(actionTypes.CANCEL_CEP_PACKAGES_REQUEST, cancelCepPackages),
    takeLatest(actionTypes.VERIFY_DOWNLOAD_TRANSMITTAL_REQUEST, verifyDownloadTransmittal),
    takeLatest(actionTypes.VERIFY_DOWNLOAD_WAYBILL_REQUEST, verifyDownloadWaybill),
    takeLatest(actionTypes.VERIFY_DOWNLOAD_PACKAGE_WAYBILL_REQUEST, verifyDownloadPackageWaybill),
  ]);
}

export default [watchCepBookingRequests()];
