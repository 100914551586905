import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as authActionCreators } from "../../../modules/auth/reducer";
import Page from "./page";
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  error: state.auth.error,
  actionType: state.auth.actionType,
  user: state.auth.profile,
  profile: state.auth.profile,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(authActionCreators, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Page));