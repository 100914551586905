import { all, takeLatest, call, put, select } from "redux-saga/effects";
import { actionTypes } from "./reducer";
import { message } from "antd";
import {
  getCepProvinces as cepServiceableProvinceService,
  getCepCities as cepServiceableCitiesService,
  getCepBarangays as cepServiceableBarangayService,
  createMPSNumber as createCepDraftBookingService,
  createCepMPSDraftBooking as createCepMPSDraftBookingService,
  updateCepDraftBooking as updateCepDraftBookingService,
  getLocalTime as getLocalTimeService,
  getPortCodes as getPortCodeService,
  getCepPackages as getCepPackagesService,
  createCepMPSPackage as createCepMPSPackageService,
  updateCepPackage as updateCepPackageService,
  cepBulkUpload as bulkUploadService,
  getCepBookingDetails as getCepBookingDetailsService,
  deleteCepPackages as deleteCepPackageService,
  cancelCepPackages as cancelCepPackageService,
  getMPSBookings as getMPSBookingsService,
  verifyDownloadTransmittal as verifyDownloadTransmittalService,
  verifyDownloadWaybill as verifyDownloadWaybillService,
  verifyDownloadPackageWaybill as verifyDownloadPackageWaybillService,

} from "./service";
import processAction from "../../utils/processAction";


function* cepBulkUpload({ body, bookingNumber }) {
  try {
    const data = yield call(bulkUploadService, body, bookingNumber);
    const failedPackages = Object.keys(data.packages).reduce((acc, batchKey) => {
      const invalidPackages = data.packages[batchKey].filter(pkg => !pkg.valid);
      acc[batchKey] = invalidPackages.map(pkg => ({
          row: pkg.packageInfo.row,
          errors: pkg.errors,
          description: pkg.packageInfo.details.description
      }));
      return acc;
    }, {});
    
    const successfulPackages = Object.keys(data.packages).reduce((acc, batchKey) => {
      const validPackages = data.packages[batchKey].filter(pkg => pkg.valid);
      const currentLength = acc.length;
      const newPackages = validPackages.map((pkg, i) => {
          pkg.packageInfo.row = currentLength + i + 1;
          
          return pkg.packageInfo; 
      });
      acc.push(...newPackages); 

      return acc;
    }, []);

    let totalPackageCount = 0;
    let failedPackageCount = 0;
    
    for (let key of Object.keys(data.summary)) {
      totalPackageCount += data.summary[key].total
      failedPackageCount += data.summary[key].failed
    }

    message.success('Upload Success');
    yield put({
      type: actionTypes.BULK_CEP_MPS_UPLOAD_SUCCESS, data: {
        successfulPackages,
        bulkErrors: {
          packages: failedPackages,
          totalPackages: totalPackageCount,
          failedPackages: failedPackageCount,
        },
      },
    });
  } catch (error) {
    if(error.details === 'Some required columns not supplied: batch'){
      message.error('You’re booking MPS shipments. Batch Column is mandatory.')
    } else {
      message.error(error.details)
    }
    yield put({ type: actionTypes.BULK_CEP_MPS_UPLOAD_FAILED, error });
  }
}

function* getCepPackages({ id }) {
  yield processAction({
    params: id,
    service: getCepPackagesService,
    success: actionTypes.GET_CEP_MPS_PACKAGES_SUCCESS,
    failed: actionTypes.GET_CEP_MPS_PACKAGES_FAILED,
  });
}

function* verifyDownloadTransmittal({ bookingNumber }) {
  try {
    yield call(verifyDownloadTransmittalService,bookingNumber);
    yield put({
      type: actionTypes.VERIFY_DOWNLOAD_MPS_TRANSMITTAL_SUCCESS,
    });
  }
  catch (error) {
    message.error(error.message);
    yield put({ type: actionTypes.VERIFY_DOWNLOAD_MPS_TRANSMITTAL_FAILED, error });
  }
}

function* verifyDownloadWaybill({ bookingNumber }) {
  try {
    yield call(verifyDownloadWaybillService,bookingNumber);
    yield put({
      type: actionTypes.VERIFY_DOWNLOAD_MPS_WAYBILL_SUCCESS,
    });
  }
  catch (error) {
    yield put({ type: actionTypes.VERIFY_DOWNLOAD_MPS_WAYBILL_FAILED, error });
  }
}

function* verifyDownloadPackageWaybill({ trackingNumber }){
  try {
    yield call(verifyDownloadPackageWaybillService,trackingNumber);
    yield put({
      type: actionTypes.VERIFY_DOWNLOAD_MPS_PACKAGE_WAYBILL_SUCCESS,
    });
  }
  catch (error) {
    yield put({ type: actionTypes.VERIFY_DOWNLOAD_MPS_PACKAGE_WAYBILL_FAILED, error });
  }
}

function* getCepProvinces() {
  yield processAction({
    service: cepServiceableProvinceService,
    success: actionTypes.GET_CEP_MPS_SERVICEABLE_PROVINCES_SUCCESS,
    failed: actionTypes.GET_CEP_MPS_SERVICEABLE_PROVINCES_FAILED,
  });
}

function* getCepCities({ id }) {
  try {
    const payload = yield call(cepServiceableCitiesService, id);
    yield put({ type: actionTypes.GET_CEP_MPS_SERVICEABLE_CITIES_SUCCESS, payload });
  } catch (error) {
    if (error.status === "NOT_FOUND") {
      const state = yield select();
      const provinces = state.cepBooking.provinces;
      if (provinces && provinces.length > 0) {
        const province = provinces.find((x) => x.id === id);
        const { name = "" } = province;
        message.error(`${name} Province is not covered at the moment.`);
      }
    }
    message.error(error.message);
    yield put({ type: actionTypes.GET_CEP_MPS_SERVICEABLE_CITIES_FAILED, error });
  }
}

function* getPortCodes({ body }) {
  try {
    const payload = yield call(getPortCodeService, body);
    yield put({ type: actionTypes.GET_MPS_PORT_CODES_SUCCESS, payload });
  } catch (error) {
    yield put({ type: actionTypes.GET_MPS_PORT_CODES_FAILED, error });
  }
}

function* getCepBarangays({ id }) {
  yield processAction({
    params: id,
    service: cepServiceableBarangayService,
    success: actionTypes.GET_CEP_MPS_SERVICEABLE_BARANGAYS_SUCCESS,
    failed: actionTypes.GET_CEP_MPS_SERVICEABLE_BARANGAYS_FAILED,
  });
}

function* getPickupLocation() {
  try {
    const state = yield select();
    yield put({ type: actionTypes.GET_MPS_LOCATION_SUCCESS, state });
  } catch (error) {
    yield put({ type: actionTypes.GET_MPS_LOCATION_FAILED, error });
  }
};

function* createCepMPSPackage({ bodies }) {
  const responseList = {}
  for (let body of bodies) {
    try {
      const data = yield call(createCepMPSPackageService, body);
      responseList[body.batchNumber] = data
    } catch (error) {
      responseList[body.batchNumber] = error
    }
  };
  
  yield put({
    type: actionTypes.CREATE_CEP_MPS_PACKAGE_SUCCESS, data: responseList
  });
}

function* getAllBookingPackages({ bookingNumberList }) {
  const responseList = {}
  
  for (let bookingNumber of bookingNumberList) {
    try {
      const data = yield call(getCepPackagesService, bookingNumber);
      responseList[bookingNumber] = data.packages
    } catch (error) {
      responseList[bookingNumber] = error
    }
  };
  
  yield put({
    type: actionTypes.GET_ALL_BOOKING_PACKAGES_SUCCESS, data: responseList
  });
}

function* updateCepPackage({ body }) {
  yield processAction({
    params: body,
    service: updateCepPackageService,
    success: actionTypes.UPDATE_CEP_MPS_PACKAGE_SUCCESS,
    failed: actionTypes.UPDATE_CEP_MPS_PACKAGE_FAILED
  });
}

function* getLocalTime() {
  try {
    const payload = yield call(getLocalTimeService);
    
    if (payload.formatted && payload.formatted) {
      yield put({ type: actionTypes.GET_MPS_LOCAL_TIME_SUCCESS, payload });
    } else {
      yield put({ type: actionTypes.GET_MPS_LOCAL_TIME_ERROR });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_MPS_LOCAL_TIME_ERROR });
  }
}

function* createMPSNumber({ body }) {
  yield processAction({
    params: body,
    service: createCepDraftBookingService,
    success: actionTypes.CREATE_MPS_NUMBER_SUCCESS,
    failed: actionTypes.CREATE_MPS_NUMBER_FAILED
  });
}

function* createCepMPSDraftBooking({ bodies }) {
  const responseList = {}

  for (let body of bodies) {
    try {
      const data = yield call(createCepMPSDraftBookingService, body);
      responseList[body.batch] = data
    } catch (error) {
      responseList[body.batch] = error
    }
  };

  yield put({
    type: actionTypes.CREATE_CEP_MPS_DRAFT_BOOKING_SUCCESS, data: responseList
  });
}

function* updateCepDraftBooking({ body }) {
  yield processAction({
    params: body,
    service: updateCepDraftBookingService,
    success: actionTypes.UPDATE_CEP_MPS_DRAFT_BOOKING_SUCCESS,
    failed: actionTypes.UPDATE_CEP_MPS_DRAFT_BOOKING_FAILED
  });
}

function* finalizeCepBooking({ bodies }) {
  const responseList = {}
  let error = null

  for (let body of bodies) {
    try {
      const data = yield call(updateCepDraftBookingService, body);
      responseList[body.bookingNumber] = data
    } catch (e) {
      error = e
    }
  };

  error ? 
    yield put({ 
      type: actionTypes.FINALIZE_CEP_MPS_BOOKING_FAILED, error
    }) :
    yield put({
      type: actionTypes.FINALIZE_CEP_MPS_BOOKING_SUCCESS, data: responseList
    });
}

function* cancelCepBooking({ bodies }) {
  const responseList = {}
  let error = null;

  for (let body of bodies) {
    try {
      const data = yield call(updateCepDraftBookingService, body);
      responseList[body.bookingNumber] = data
    } catch (e) {
      error = e;
    }
  };

  error ?
    yield put({ 
      type: actionTypes.CANCEL_CEP_MPS_BOOKING_FAILED, error 
    }) : 
    yield put({
      type: actionTypes.CANCEL_CEP_MPS_BOOKING_SUCCESS, data: responseList
    });
}

function* deleteCepPackages({body} ) {
  try {
    yield call(deleteCepPackageService, body); 
    
    message.success('Package Successfully Deleted');
    yield put({ type: actionTypes.DELETE_CEP_MPS_PACKAGES_SUCCESS, payload: body });
  } catch (error) {
    yield put({ type: actionTypes.DELETE_CEP_MPS_PACKAGES_FAILED, error });
  }
}

function* cancelCepPackages({body} ) {
  try {
    let payload = yield call(cancelCepPackageService, body);
    
    message.success('Package is Successfully Cancelled');
    yield put({ type: actionTypes.CANCEL_CEP_MPS_PACKAGES_SUCCESS, payload: payload.packages });
    yield put({ type: actionTypes.GET_CEP_MPS_PACKAGES, id: body.bookingNumber });
  } catch (error) {
    yield put({ type: actionTypes.CANCEL_CEP_MPS_PACKAGES_FAILED, error });
  }
}

function* getCepBookingDetails({ id }) {
  try {
    let payload = yield call(getCepBookingDetailsService, id);
    const state = yield select();
    const bookingAccountId = payload.accountId;
    const { cepDashboard } = state;
    
    const { cepProfile, cepAccountDetails } = cepDashboard;

    const { accountId } = cepProfile || {};
    let cepaccountId
    if(accountId) {
      cepaccountId = accountId;
    }  else {
      cepaccountId = cepAccountDetails[0].accountId;
    }

    if (bookingAccountId !== cepaccountId) {
      message.error('Client Booking Mismatch');
      payload.isMismatch = true;
    }else{
      payload.isMismatch = false;
    }

    yield put({ type: actionTypes.GET_CEP_MPS_BOOKING_DETAILS_SUCCESS, payload });
  } catch (error) {
    yield put({ type: actionTypes.GET_CEP_MPS_BOOKING_DETAILS_FAILED, error });
  }
}

function* getMPSBookings({body}){
  yield processAction({
    params: body,
    service: getMPSBookingsService,
    success: actionTypes.GET_CEP_MPS_BOOKINGS_SUCCESS,
    failed: actionTypes.GET_CEP_MPS_BOOKINGS_FAILED,
  });
}

function* watchCepBookingRequests() {
  yield all([
    takeLatest(actionTypes.GET_CEP_MPS_SERVICEABLE_PROVINCES_REQUEST, getCepProvinces),
    takeLatest(actionTypes.GET_CEP_MPS_SERVICEABLE_CITIES_REQUEST, getCepCities),
    takeLatest(actionTypes.GET_CEP_MPS_SERVICEABLE_BARANGAYS_REQUEST, getCepBarangays),
    takeLatest(actionTypes.GET_MPS_LOCATION_REQUEST, getPickupLocation),
    takeLatest(actionTypes.GET_MPS_LOCAL_TIME_REQUEST, getLocalTime),
    takeLatest(actionTypes.GET_MPS_PORT_CODES_REQUEST, getPortCodes),
    takeLatest(actionTypes.GET_CEP_MPS_BOOKINGS_REQUEST, getMPSBookings),
    takeLatest(actionTypes.GET_ALL_BOOKING_PACKAGES_REQUEST, getAllBookingPackages),
    takeLatest(actionTypes.CREATE_MPS_NUMBER_REQUEST, createMPSNumber),
    takeLatest(actionTypes.CREATE_CEP_MPS_DRAFT_BOOKING_REQUEST, createCepMPSDraftBooking),
    takeLatest(actionTypes.UPDATE_CEP_MPS_DRAFT_BOOKING_REQUEST, updateCepDraftBooking),
    takeLatest(actionTypes.GET_CEP_MPS_PACKAGES, getCepPackages),
    takeLatest(actionTypes.CREATE_CEP_MPS_PACKAGE_REQUEST, createCepMPSPackage),
    takeLatest(actionTypes.UPDATE_CEP_MPS_PACKAGE, updateCepPackage),
    takeLatest(actionTypes.BULK_CEP_MPS_UPLOAD_REQUEST, cepBulkUpload),
    takeLatest(actionTypes.FINALIZE_CEP_MPS_BOOKING_REQUEST, finalizeCepBooking),
    takeLatest(actionTypes.CANCEL_CEP_MPS_BOOKING_REQUEST, cancelCepBooking),
    takeLatest(actionTypes.GET_CEP_MPS_BOOKING_DETAILS_REQUEST, getCepBookingDetails),
    takeLatest(actionTypes.DELETE_CEP_MPS_PACKAGES_REQUEST, deleteCepPackages),
    takeLatest(actionTypes.CANCEL_CEP_MPS_PACKAGES_REQUEST, cancelCepPackages),
    takeLatest(actionTypes.VERIFY_DOWNLOAD_MPS_TRANSMITTAL_REQUEST, verifyDownloadTransmittal),
    takeLatest(actionTypes.VERIFY_DOWNLOAD_MPS_WAYBILL_REQUEST, verifyDownloadWaybill),
    takeLatest(actionTypes.VERIFY_DOWNLOAD_MPS_PACKAGE_WAYBILL_REQUEST, verifyDownloadPackageWaybill),
  ]);
}

export default [watchCepBookingRequests()];
