
import { all, takeLatest, call, put, select } from "redux-saga/effects";
import { delay } from 'redux-saga'
import { actionTypes } from "./reducer";
import {
  getAllCepBookingAccounts as getAllDashboardAccountService,
  getCepClientDetails as getCepClientDetailsService,
  getCepUserBookings as getCepUserBookingService,
  getCepUserDetails as getCepUserDetailsService,
  getCepBookingStatus as getCepBookingStatusService,
  getCepProfileDetails as getCepProfileDetailsService,
} from "./service";


function* getAllCepBookingAccounts(limit) {
  try {
    const payload = yield call(getAllDashboardAccountService, limit);
    
    if (Object.keys(payload.meta.relLinks).length) {
      payload.meta.count += 1;
    }
    yield put({ type: actionTypes.GET_ALL_CEP_MPS_BOOKING_ACCOUNT_SUCCESS, payload });
  } catch (error) {
    yield put({ type: actionTypes.GET_ALL_CEP_MPS_BOOKING_ACCOUNT_FAILED, error });
  }
}

function* getCepClientDetails(client) {
  try {
    const payload = yield call(getCepClientDetailsService, client.id);
    
    yield put({ type: actionTypes.GET_CEP_MPS_CLIENT_DETAILS_SUCCESS, payload });
  } catch (error) {
    yield put({ type: actionTypes.GET_CEP_MPS_CLIENT_DETAILS_FAILED, error });
  }
}

function* getCepUserDetails(distinctIds) {
  try {
    const state = yield select();
    const { profile } = state.auth;
    let userDetails = [profile];
    
    const payload = yield call(getCepUserDetailsService, distinctIds.body);
    payload.users.map((v) => {
      const found = profile.userId === v.userId ? profile.userId : '';
      if (!found) {
        userDetails.push(v);
      }
      return v;
    });
    
    yield put({ type: actionTypes.GET_CEP_MPS_USER_DETAILS_SUCCESS, data: userDetails });
  } catch (error) {

    yield put({ type: actionTypes.GET_CEP_MPS_USER_DETAILS_FAILED, error });
  }
}


function* getCepUserBookings(distinctIds) {
  try {
    const payload = yield call(getCepUserBookingService, distinctIds.body);
    if (Object.keys(payload.meta.relLinks).length) {
      payload.meta.count += 1;
    }
    yield put({ type: actionTypes.GET_CEP_MPS_USER_BOOKINGS_SUCCESS, data: payload });
    
  } catch (error) {
    yield put({ type: actionTypes.GET_CEP_MPS_USER_BOOKINGS_FAILED, error });
  }
}

function* getCepBookingStatus({ bookingNumber }) {
  try {
    const payload = yield call(getCepBookingStatusService, bookingNumber);
    yield delay(5000);
    const state = yield select();
    const { cepDashboard } = state;
    const { cepBookings } = cepDashboard;
    
    const filterStatusProcessing = cepBookings.bookings.filter(
      (x) => x.status === 'PROCESSING'
    );
    const filterBookingNumber = cepBookings.bookings.findIndex(
      (b) => b.bookingNumber === bookingNumber
    );
    const currentIndex = filterStatusProcessing.findIndex(
      (b) => b.bookingNumber === bookingNumber
    );

    if (filterBookingNumber >= 0) {
      const currentCount = cepBookings.bookings[filterBookingNumber].count
        ? cepBookings.bookings[filterBookingNumber].count
        : 0;
      cepBookings.bookings[filterBookingNumber].status = payload.status;
      cepBookings.bookings[filterBookingNumber].count = currentCount + 1;
    }
    yield put({ type: actionTypes.GET_CEP_MPS_BOOKING_STATUS_SUCCESS, payload: cepBookings });
    const pathName = window.location.pathname;
    
    if (
      cepBookings.bookings[filterBookingNumber].count <= 10 &&
      pathName === '/mpsdashboard'
    ) {
      if (
        filterStatusProcessing[currentIndex + 1] &&
        filterStatusProcessing.length > 0
      ) {
        yield put({
          type: actionTypes.GET_CEP_MPS_BOOKING_STATUS,
          bookingNumber: filterStatusProcessing[currentIndex + 1].bookingNumber,
        });
      } else {
        yield put({
          type: actionTypes.GET_CEP_MPS_BOOKING_STATUS,
          bookingNumber: filterStatusProcessing[0].bookingNumber,
        });
      }
    }

  } catch (error) {
    yield put({ type: actionTypes.GET_CEP_MPS_BOOKING_STATUS_FAILED, error });
  }
}


function* cepBookingUpdateType({ updateType }) {
  try { 
    yield put({type: actionTypes.UPDATE_CEP_MPS_BOOKING_UPDATE_TYPE_SUCCESS, updateType});
  } catch (error) {
  }
}

function* getCepProfileDetails({ id }) {
  try {
    const payload = yield call(getCepProfileDetailsService, id);
    
    yield put({ type: actionTypes.GET_CEP_MPS_PROFILE_SUCCESS, payload });
  } catch (error) {
    yield put({ type: actionTypes.GET_CEP_MPS_PROFILE_FAILED, error });
  }
}

function* watchCepDashboardRequests() {
  yield all([
    takeLatest(actionTypes.GET_ALL_CEP_MPS_BOOKING_ACCOUNT_REQUEST, getAllCepBookingAccounts),
    takeLatest(actionTypes.GET_CEP_MPS_CLIENT_DETAILS_REQUEST, getCepClientDetails),
    takeLatest(actionTypes.GET_CEP_MPS_USER_BOOKINGS_REQUEST, getCepUserBookings),
    takeLatest(actionTypes.GET_CEP_MPS_USER_DETAILS_REQUEST, getCepUserDetails),
    takeLatest(actionTypes.GET_CEP_MPS_BOOKING_STATUS, getCepBookingStatus),
    takeLatest(actionTypes.UPDATE_CEP_MPS_BOOKING_UPDATE_TYPE, cepBookingUpdateType),
    takeLatest(actionTypes.GET_CEP_MPS_PROFILE, getCepProfileDetails),
  ]);
}

export default [watchCepDashboardRequests()];
