import { getToken } from "./token";
import urlFormat from "./urlFormat";
// import { codeMessage } from "./errors";

const API_URL = `${process.env.REACT_APP_API_URL}`;
const API_ONDEMAND_URL = `${process.env.REACT_APP_ONDEMAND_API_URL}`;

const fetchOpts = (method, body = null) => {
  const accessToken = getToken();

  let option = null;
  if (body instanceof FormData) {
    option = getFormDataOption(method, body, accessToken);
  } else {
    option = getJsonOption(method, body, accessToken);
  }
  return option;
};

const getJsonOption = (method, body, accessToken) => {
  let option = Object.assign(
    {},
    {
      method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    },
    body
      ? {
        body: JSON.stringify(body)
      }
      : {}
  );

  if (accessToken) {
    option = Object.assign(
      {},
      {
        method,
        headers: {
          Accept: "application/json, application/patch-status-update+json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        }
      },
      body
        ? {
          body: JSON.stringify(body)
        }
        : {}
    );
  }

  return option;
};

const getFormDataOption = (method, body, accessToken) => {
  let option = Object.assign(
    {},
    {
      method,
      headers: {
        Accept: "application/json"
      }
    },
    body
      ? {
        body: body
      }
      : {}
  );

  if (accessToken) {
    option = Object.assign(
      {},
      {
        method,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`
        }
      },
      body
        ? {
          body: body
        }
        : {}
    );
  }

  return option;
};

// export const API = {
//   get: (url, data , customDomain) => {
//     if( customDomain ){
//       return fetch(`${customDomain}${url}`, fetchOpts("GET"))
//     }
//     return fetch(`${API_URL}${url}`, fetchOpts("GET"))
//   },
//   post: (url, data) => fetch(`${API_URL}${url}`, fetchOpts("POST", data)),
//   put: (url, data) => fetch(`${API_URL}${url}`, fetchOpts("PUT", data)),
//   patch: (url, data) => fetch(`${API_URL}${url}`, fetchOpts("PATCH", data)),
//   delete: url => fetch(`${API_URL}${url}`, fetchOpts("DELETE"))
// };


export const API = {
  get: (url, body, customDomain) => {
    if (customDomain) {
      if (customDomain !== API_URL) {
        return fetch(`${customDomain}${url}`, fetchOpts('GET', '', true));
      }
      return fetch(`${customDomain}`, fetchOpts('GET'));
    }
    return fetch(`${API_URL}${url}`, fetchOpts('GET'));
  },
  post: (url, data, customDomain) => {
    if (customDomain) {
      if (customDomain !== API_URL) {
        return fetch(`${customDomain}${url}`, fetchOpts('POST', data, true));
      }
      return fetch(`${customDomain}`, fetchOpts('POST', data));
    }
    if (url === "pcal/calculate-fees/retail/consumer" || url === "pcal/calculate-fees/retail/consumer/bulk") {
      return fetch(`${API_ONDEMAND_URL}${url}`, fetchOpts('POST', data));
    }

    return fetch(`${API_URL}${url}`, fetchOpts('POST', data));
  },
  put: (url, data, customDomain) => {
    if (customDomain) {
      if (customDomain !== API_URL) {
        return fetch(`${customDomain}${url}`, fetchOpts('PUT', data, true));
      }
      return fetch(`${customDomain}`, fetchOpts('PUT', data));
    }
    return fetch(`${API_URL}${url}`, fetchOpts('PUT', data));
  },
  patch: (url, data, customDomain) => {
    if (customDomain) {
      if (customDomain !== API_URL) {
        return fetch(`${customDomain}${url}`, fetchOpts('PATCH', data, true));
      }
      return fetch(`${customDomain}`, fetchOpts('PATCH', data));
    }
    return fetch(`${API_URL}${url}`, fetchOpts('PATCH', data));
  },
  delete: (url, data) => fetch(`${API_URL}${url}`, fetchOpts('DELETE', data)),
};

const ApiUrlException = message => ({ message });

const request = async (url, options = {}, customDomain) => {

  let method = "get";
  if (options.method) {
    method = options.method.toLowerCase();
  }

  // Get params
  if (options.params) {
    url = urlFormat(url, options.params);
  }

  let body = null;
  if (options.body) {
    body = options.body;
  }

  // Do the request
  try {
    if (API_URL === "") {
      throw new ApiUrlException("Server Error");
    }

    const response = await API[method](url, body, customDomain);
    const json = await response.text();
    if (!response.ok) {
      // original
      // throw codeMessage[json.error_code];
      // modified
      throw json;
    }

    if (!json) {
      return {};
    } else return parseJson(json);
  } catch (err) {
    throw parseJson(err);
  }
};

const parseJson = (json) => {
  try {
    return JSON.parse(json);
  } catch (err) {
    return {};
  }
}

export default request;
