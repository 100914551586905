import Loadable from "react-loadable";
import LoadingComponent from "../../commons/Loader";

export default [
  {
    path: "/cepmpsbooking/:id",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages"),
      loading: LoadingComponent
    })
  },
  {
    path: "/cepmpsbooking/:id/:id/:id/:id",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./cep-viewedit-booking/pages"),
      loading: LoadingComponent
    })
  }
];
