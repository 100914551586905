import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Spin } from "antd";
import Header from "./commons/Header";
import Footer from "./commons/Footer";
import CacheBuster from './commons/CacheBuster';
import "./App.css";
import SideNav from "./commons/SideNav/pages";
import ModifiedHeader from "./commons/ModifiedHeader/pages";
import { withRouter } from 'react-router-dom';
import IdleTimerContainer from "./commons/IdleTimerContainer";

const { Content } = Layout;

class App extends Component {
  renderLoginPage() {
    return (
      <div className="app">
        <main className="main">
          <Header profile={"HELLO WORLD"} />
          <Layout id="auth" className="pt-3">
            <Content>{this.props.children}</Content>
          </Layout>
        </main>
        <Footer />
      </div>
    );
  }


  renderPrivatePage() {
    const { history } = this.props;
    const bookingNumber = history.location.pathname.split('/')[3];
    const mpsNumber = history.location.pathname.split('/')[3];
    const acoountId = history.location.pathname.split('/')[4];
    const bookingType = history.location.pathname.split('/')[2];
    const tabIndex = history.location.pathname.split('/')[4];
    const mpsTabIndex = history.location.pathname.split('/')[5];
   
    return (
      <Layout style={{ minHeight: '100vh' }}>
        {((this.props.history.location.pathname !== `/cepbooking/${bookingType}` && this.props.history.location.pathname !== `/cepbooking/${bookingType}/${bookingNumber}/${tabIndex}`) &&
        (this.props.history.location.pathname !== `/cepmpsbooking/${bookingType}` && this.props.history.location.pathname !== `/cepmpsbooking/${bookingType}/${mpsNumber}/${acoountId}/${mpsTabIndex}`)) ? 
        <SideNav /> : null}
        
        <Layout className="site-layout">
          {((this.props.history.location.pathname !== `/cepbooking/${bookingType}` && this.props.history.location.pathname !== `/cepbooking/${bookingType}/${bookingNumber}/${tabIndex}`) &&
          (this.props.history.location.pathname !== `/cepmpsbooking/${bookingType}` && this.props.history.location.pathname !== `/cepmpsbooking/${bookingType}/${mpsNumber}/${acoountId}/${mpsTabIndex}`)) ? 
          <ModifiedHeader profile={this.props.profile}
            /> : null}

          <Content style={{ overflowX: "hidden" }}>
            <div>
              {React.cloneElement(this.props.children, { profile: this.props.profile })}
            </div>
            <IdleTimerContainer />
          </Content>
        </Layout>
      </Layout>
    );
  }

  render() {
    const { isAuthenticated, isLoading } = this.props;
    
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) {
            return null;
          }
          
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          
          if (isAuthenticated) {
            return this.renderPrivatePage();
          }

          return <Spin spinning={isLoading}>{this.renderLoginPage()}</Spin>;
        }}
      </CacheBuster>
    )
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.profile.isLoading,
  profile: state.auth.profile
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));
