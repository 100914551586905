import { all } from "redux-saga/effects";

import authSagas from "../modules/auth/saga";
import profileSagas from "../modules/profile/saga";
import locationSagas from "../modules/location/saga";
import creditsSagas from "../modules/credits/saga";
import bookingSaga from "../modules/booking/saga";
import packageSaga from "../modules/packages/saga";
import adminSaga from "../modules/admin/saga";
import viewBookingSaga from "../modules/dashboard/saga";
import coverageSaga from "../modules/coverage/saga";
import homeSaga from "../modules/home/saga";
import pouchBoxSaga from "../modules/pouch-box-request/saga";
import shippingFee from "../modules/shipping-fee/saga";
import cepBookingSagas from "../modules/cep-booking/saga";
import cepDashboardSagas from "../modules/cep-dashboard/saga";
import cepMPSBookingSagas from "../modules/cep-mps-booking/saga";
import cepMPSDashboardSagas from "../modules/cep-mps-dashboard/saga";


export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...profileSagas,
    ...creditsSagas,
    ...locationSagas,
    ...bookingSaga,
    ...packageSaga,
    ...adminSaga,
    ...viewBookingSaga,
    ...coverageSaga,
    ...homeSaga,
    ...pouchBoxSaga,
    ...shippingFee,
    ...cepBookingSagas,
    ...cepDashboardSagas,
    ...cepMPSBookingSagas,
    ...cepMPSDashboardSagas,
  ]);
}
