import request from "../../utils/api";

export const getCepProvinces = () => request(`locations/provinces`);

export const getCepCities = (id) => request(`locations/provinces/${id}/cities/serviceable`);

export const getCepBarangays = (id) => request(`locations/cities/${id}/barangays`);

export const createMPSNumber = (body) => request(`pbookings/bookings/mps`, { method: "POST", body });

export const createCepMPSDraftBooking = (body) => request(`pbookings/bookings/mps/${body.mpsNumber}`, { method: "POST", body });

export const updateCepDraftBooking = (body) => request(`pbookings/bookings/`, { method: "PATCH", body });

export const getLocalTime = () => request(
  `get-time-zone?key=KZRHVKLMCDMW&format=json&by=zone&zone=Asia/Manila`, 
  {}, 
  'https://api.timezonedb.com/v2.1/'
);

export const getPortCodes = (body) => request(`locations/port-codes/coverage?provinceName=${body.provinceName}&cityName=${body.cityName}`);

export const getCepPackages = (id) => request(`pbookings/bookings/${id}/packages`, {});

export const getCepBookingDetails = (id) => request(`pbookings/bookings/${id}`, {});

export const getProfile = (id) => request(`pbookings/accounts/personnel/verification/${id}`);

export const createCepMPSPackage = (body) => request(`pbookings/bookings/${body.bookingNumber}/packages`, { method: "POST", body });

export const updateCepPackage = (body) => request(`pbookings/bookings/${body.bookingNumber}/packages`, { method: 'PATCH', body: body.packages });

export const cepBulkUpload = (body) => request(`pbookings/bookings/mps/validate`, { method: "POST", body });

export const cancelCepPackages = (body) => request(`pbookings/package/cancel`, { method: "POST", body });

export const deleteCepPackages = (body) => request(`pbookings/bookings/${body.bookingNumber}/packages`, { method: "DELETE", body: body.listOfPackages });

export const getMPSBookings = (body) => request(`pbookings/bookings/mps/${body.mpsNumber}?accountId=${body.accountId}`, {});

export const verifyDownloadTransmittal = (bookingNumber) => request(`waybills/transmittal/document/${bookingNumber}`, {});

export const verifyDownloadWaybill = (bookingNumber) => request(`waybills/transmittal/document/${bookingNumber}/waybills`, {});

export const verifyDownloadPackageWaybill = (trackingNumber) => request(`waybills/cep/${trackingNumber}`, {});

